<template>
  <div class="skingepage">
    <tabBar :cardIndex="0" :activeIndexs="1" />
    <div class="skinwbanner">
      <div class="skinwbanner_bgs">
        <div class="skinwbanner_center">
          <!-- <img class="skin_left" src="../assets/img/go-model.png" alt="" /> -->
          <div></div>
          <div class="slin_right">
            <!-- <img class="imadesli" src="../assets/img/tuopanliusts.png" alt="" /> -->
            <!-- <div class="right_title">随时随地<br />奥普斯®随时白</div> -->
            <!-- <div class="right_text">
              Opalescence
              Go™奥普斯®随时白美白托盘搭配专业牙齿美白配方，随时随地，美白牙齿，口气清新！创新的UltraFit™
              专利设计托盘满足任何口型，即拿即用，绽放自由微笑。简单、便捷、高效美白。随时随地，奥普斯®随时白。
            </div> -->
            <!-- <div class="right_btn" @click="toplooke(2)">
              立即购买 <span class="span"></span>
            </div> -->
          </div>
        </div>
        <!-- <triangle></triangle> -->
      </div>
    </div>
    <div class="cdlsitad">
      <homeDrag :backgroundColor="backgroundColor" :bannerList="1"></homeDrag>
      <div class="textshuakuai">
        将滑块向左右滑动查看<br />
        Opalescence GO™奥普斯®随时白®出色的牙齿美白效果！
      </div>
    </div>
    <div class="navlsitone">
      <div class="navlsitone_cemter">
        <div style="margin-right: 20px">
          <div class="titles">
            随时白®牙齿美白托盘<br />
            产品亮点
          </div>
          <ul class="uls">
            <li class="li">无需牙医专业定制托盘</li>
            <li class="li">即拿即用</li>
            <li class="li">UltraFit™专利设计托盘适合任何口型，绽放自由微笑</li>
            <li class="li">
              有效成分：3%过氧化氢（佩戴30-60分钟，连续使用5-10天）
            </li>
            <li class="li">牙齿美白凝胶含有硝酸钾和氟化物，舒适化美白牙齿</li>
            <li class="li">口味：清凉薄荷味</li>
            <li class="li">满足素食主义者需求，不使用动物产品</li>
            <li class="li">不含麸质，减少过敏，专业认证</li>
            <li class="li">Kosher认证-犹太食品认证</li>
          </ul>
        </div>
        <img
          class="posiimgs"
          src="../assets/img/go-productpackaging-2.png"
          alt=""
        />
        <div class="iconlsit">
          <img src="../assets/img/icon1.png" alt="" />
          <img class="imgones" src="../assets/img/icon2.png" alt="" />
          <img class="imgther" src="../assets/img/icon3.png" alt="" />
        </div>
      </div>
    </div>
    <div class="navlsittwo">
      <div class="navlsittwo_title">如何使用 Opalescence Go 牙齿美白</div>
      <div class="navlsittwo_center">
        <img
          class="navlsittwo_imgsone"
          src="../assets/img/bg-goModel.png"
          alt=""
        />
        <img
          class="navlsittwo_imgstwo"
          src="../assets/img/product-infoImage.png"
          alt=""
        />
        <div class="navlsittwo_imgsther"  @click="videtips">
          <img
            class="imgsgubi"
            src="../assets/img/xiazais.png"
            alt=""
           
          />
          <img class="playing"  @click="videtips" src="../assets/img/playigm.png" alt="" />
        </div>
      </div>
      <!-- <video :src="coverUrl" controls style="width: 300px"></video>
      <img :src="imgSrc" />
      <div>
        <botton @click="cutPicture"> 拍照 </botton>
      </div>
      <canvas id="myCanvas" width="626" height="353"></canvas> -->
    </div>
    <div class="zhinan">
      <div class="zhinan_center">
        <div class="zhinan_title">随时白®使用指南</div>
        <div class="ul_li">
          <div
            :class="index == 1 || index == 4 ? 'uls marsds' : 'uls'"
            v-for="(item, index) in imglsit"
            :key="index"
          >
            <img class="buzouimg" :src="item.img" alt="" />
            <div class="text_list" v-html="item.name">
              <!-- {{ item.name }} -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="teeth">
      <div class="teeth_center">
        <img class="teeth_one" src="../assets/img/Dentist.png" alt="" />
        <div class="teeth_two">
          <img class="posotopms" src="../assets/img/dizhi.png" alt="" />
          <div class="titles">从今天开始美白</div>
          <div class="sherch">
            搜索寻找附近的皓齿美白大师，<br/>量身定制最适合您的牙齿美白方案！
          </div>
          <div class="btnsad" @click="toplooke">寻找您附近的美白牙医</div>
        </div>
      </div>
    </div>
    <div class="message">更多牙齿美白相关信息，请咨询您的牙科医生</div>
    <div class="goodes">
      <div class="goodes_title">更多美白选择</div>
      <div class="centers">
        <img
          class="centersone"
          src="../assets/img/opalToothpaste-option.png"
          alt=""
          @click="goyagaoshangcheng(1)"
        />
        <img
          class="centerstwo"
          src="../assets/img/shangchengasa.png"
          alt=""
          @click="goyagaoshangcheng(2)"
        />
      </div>
    </div>
    <footers></footers>

    <div class="moadals" v-if="dialogVisible">
      <div class="modalscenter">
        <div class="removeguanboi" @click="removemodal">x</div>
        <video
          :controls="videoOptions.controls"
          webkit-playsinline="true"
          playsinline="true"
          x-webkit-airplay="allow"
          x5-playsinline
          @play="onPlayerPlay"
          @pause="onPlayerPause"
          @seeking="seeking"
          autoplay="autoplay"
          ref="video"
          class="vadeols"
          id="videoPlay"
          src="https://opalescence.oss-cn-beijing.aliyuncs.com/video/How%20to%20use%20Opalescence%20Go%20Dentsit%20Version%20YT%20China%20New%20Final-1920.mp4?versionId=CAEQGRiBgMD309fL.hciIDlmNDA2ZGI3YTE3YzQ3OTc5NTdmMmJmNjRmZGZlMDg4"
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import tabBar from "@/components/tabBar.vue";
import triangle from "@/components/triangle.vue";
import homeDrag from "@/components/homeDrag.vue";
import footers from "@/components/footer.vue";
export default {
  components: {
    tabBar,
    triangle,
    homeDrag,
    footers,
  },
  data() {
    return {
      imglsit: [
        {
          img: require("../assets/img/buzou1.png"),
          name: "1.从包装中取出奥普斯®随时白®牙齿美白托盘。<br/> “U”-上颌牙齿美白托盘 <br/>“L”-下颌牙齿美白托盘",
        },
        {
          img: require("../assets/img/buzou2.png"),
          name: "2.佩戴上颌牙齿美白托盘。",
        },
        {
          img: require("../assets/img/buzou3.png"),
          name: "3.咬紧，轻轻吮吸美白托盘2秒钟。",
        },
        {
          img: require("../assets/img/buzou4.png"),
          name: "4.取下绿色的外托盘，将白色内托盘留在齿上。重复下颌牙 齿美白托盘操作。",
        },
        {
          img: require("../assets/img/buzou5.png"),
          name: "5.佩戴时间结束后，取下牙齿美白托盘并刷牙。",
        },
      ],
      backgroundColor: "#00A252",
      videoOptions: {
        controls: true,
      },
      dialogVisible: false,
      imgSrc: "",
      coverUrl:
        "https://opalescence.oss-cn-beijing.aliyuncs.com/video/How%20to%20use%20Opalescence%20Go%20Dentsit%20Version%20YT%20China%20New%20Final-1920.mp4?versionId=CAEQGRiBgMD309fL.hciIDlmNDA2ZGI3YTE3YzQ3OTc5NTdmMmJmNjRmZGZlMDg4",
    };
  },
  mounted() {
    // this.cutPicture();
    this.initVideo();
  },
  methods: {
    goyagaoshangcheng(index) {
      if (index == 1) {
        this.$router.push({ path: "/scheme" });
      }
      if (index == 2) {
				// this.$message.error('此功能正在建设中，敬请期待')
				// return false
        this.$router.push({ path: "/OpusMall" });
      }
    },
    toplooke(index) {
      if(index == 2){
         this.$router.push({
        path: "./shopdet?id=1508748159384113154",
      });
      }else{
        this.$router.push({ path: "/lookingdentist" });
      }
      
    },
    seeking() {},
    videtips() {
      this.dialogVisible = true;
    },
    removemodal() {
      this.dialogVisible = false;
      this.player.dispose();
    },

    cutPicture() {
      let self = this;
      var v = document.querySelector("video");
      let canvas = document.getElementById("myCanvas");
      var ctx = canvas.getContext("2d");
      ctx.drawImage(v, 0, 0, 626, 353);
      var oGrayImg = canvas.toDataURL("image/jpeg");
      self.imgSrc = oGrayImg;
    },
    initVideo() {
      //原生初始化视频方法
      let myVideo = this.$refs.video;
      //ontimeupdate
      myVideo.ontimeupdate = function () {
        myFunction();
      };
      let _this = this;

      function myFunction() {
        let playTime = myVideo.currentTime;
        setTimeout(function () {
          localStorage.setItem("cacheTime", playTime);
        }, 500);
        let time = localStorage.getItem("cacheTime");
        // 当前播放位置发生变化时触发。
        if (playTime - Number(time) > 2) {
          myVideo.currentTime = Number(time);
        } else {
        }
      }
    },
    // 播放回调
    onPlayerPlay(player) {
      // this.globalSetting = true
      console.log("player play!", player);
      // document.getElementsByClassName("vjs-control-bar").style.display = "block";
      // document.getElementsByClassName("vjs-control-bar").style.display = "block";
    },

    // 暂停回调
    onPlayerPause(player) {
      // this.globalSetting.controls = false;
      console.log("player pause!", player);
      // var video = document.getElementById("video");
      // video.controls=false;
      // document.getElementsByClassName("vjs-control-bar").style.display = "none";
    },
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>


<style lang="scss" scoped>
.skingepage {
}
.skinwbanner {
  width: 100%;
  height: 700px;
  margin-top: 80px;
  background: url("../assets/img/tuopanhome.png") no-repeat;
  background-size: 100% 100%;
  .skinwbanner_bgs {
    width: 100%;
    height: 100%;
    // background: rgba(1, 163, 83, 0.75);
    // background: linear-gradient(90deg, #01A353, #008E48);
    overflow: hidden;
    position: relative;
    .skinwbanner_center {
      display: flex;
      justify-content: space-between;
      max-width: 1250px;
      margin: 0 auto;
      .skin_left {
        width: 620px;
      }
      .slin_right {
        // background: url("../assets/img/go-productpackaging-2.png") no-repeat;
        // background-size: 317px 353px;
        // background-position: right top;
        // margin-top: 40px;
        // padding-top: 40px;
            width: 50%;
    // padding-top: 390px;
    // position: relative;
        .imadesli {
          width: 300px;
          height: 124px;
          // margin-top: 380px;
          
        }
        .right_title {
          font-size: 36px;
          color: #ffffff;
        }
        .right_text {
          width: 611px;
          margin-top: 37px;
          font-size: 20px;
          text-align: left;
          color: #ffffff;
        }
        .right_btn {
          width: 190px;
          height: 46px;
          margin-top: 530px;
          line-height: 46px;
          text-align: center;
          background: #ffffff;
          border-radius: 2px;
          font-size: 16px;
          cursor: pointer;
          color: #00954b;
          .span {
            padding-left: 10px;
          }
        }
      }
    }
  }
}
.cdlsitad {
  height: 560px;
  margin-bottom: 66px;
}
.textshuakuai {
  width: 100%;
  margin-top: 66px;
  text-align: center;
  font-size: 28px;
  color: #535353;
}
.navlsitone {
  width: 100%;
  height: 594px;
  background: #f7f6f6;
  margin-top: 150px;
  .navlsitone_cemter {
    max-width: 1250px;
    height: 100%;
    margin: 0 auto;
    padding-left: 30px;
    // padding-top: 60px;
    display: flex;
    align-items: center;
    position: relative;
    .posiimgs {
      width: 623px;
      height: 694px;
      position: relative;
      //right: 126px;
      top: -106px;
    }
    .iconlsit {
      position: absolute;
      bottom: 0;
      bottom: 17px;
      display: flex;
      align-items: center;
      right: 460px;
      img {
        margin: 0 10px;
        width: 54px;
        height: 71px;
      }
      .imgones {
        width: 50px;
        height: 71px;
      }
      .imgther {
        width: 50px;
        height: 73px;
      }
    }
    .titles {
      // padding-left: 10px;
      font-size: 36px;
      color: #535353;
    }
    .uls {
      padding-left: 20px;
      font-size: 16px;
      color: #535353;
      line-height: 36px;
      list-style-type: disc;
    }
  }
}
.navlsittwo {
  width: 100%;
  overflow: hidden;
  padding-bottom: 45px;
  .navlsittwo_title {
    margin-top: 73px;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    color: #535353;
  }
  .navlsittwo_center {
    max-width: 1250px;
    margin: 0 auto;
    position: relative;
    padding-left: 35px;
    .navlsittwo_imgsone {
      width: 485px;
      height: 891px;
      position: absolute;
      right: 57px;
      top: 5px;
    }
    .navlsittwo_imgstwo {
      width: 670px;
      height: 350px;
      margin-top: 88px;
    }
    .navlsittwo_imgsther {
      width: 626px;
      height: 353px;
      position: relative;
      .imgsgubi {
        width: 626px;
        height: 353px;
       
      }
       .playing{
          width: 100px;
          height: 100px;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
    }
  }
}
.zhinan {
  width: 100%;
  height: 912px;
  background: #e8e8e8;
  .zhinan_center {
    width: 1250px;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    .zhinan_title {
      margin-top: 78px;
      margin-bottom: 60px;
      text-align: center;
      font-size: 36px;
      font-weight: bold;
      color: #535353;
    }
    .ul_li {
      display: flex;
      flex-flow: wrap;
      .uls {
        width: 400px;
        height: 322px;
        margin-bottom: 40px;
        .buzouimg {
          width: 400px;
          height: 217px;
        }
        .text_list {
          width: 100%;
          padding: 0 30px;
          font-size: 16px;
          margin-top: 28px;
          color: #535353;
          text-align: center;
          word-wrap: break-word;
        }
      }
      .marsds {
        margin: 0 24px;
      }
    }
  }
}
.teeth {
  width: 100%;
  height: 693px;
  .teeth_center {
    max-width: 1250px;
    margin: 0 auto;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .teeth_one {
      width: 519px;
      height: 619px;
      //margin-top: 70px;
    }
    .teeth_two {
      width: 50%;
      //height: 100%;
      margin-bottom: 62px;
      text-align: center;
      .posotopms {
        width: 77px;
      }
      .titles {
        margin-top: 29px;
        font-size: 36px;
        font-weight: bold;
        color: #535353;
      }
      .sherch {
        margin-top: 46px;
        font-size: 24px;
        color: #535353;
      }
      .btnsad {
        width: 245px;
        height: 48px;
        line-height: 48px;
        margin: 0 auto;
        margin-top: 70px;
        text-align: center;
        background: #00a252;
        border-radius: 2px;
        font-size: 16px;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}
.message {
  width: 100%;
  height: 60px;
  text-align: center;
  line-height: 60px;
  background: #c2c6cc;
  font-size: 24px;
  color: #ffffff;
}
.goodes {
  width: 100%;
  padding-bottom: 88px;
  .goodes_title {
    width: 100%;
    margin-top: 72px;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #535353;
  }
  .centers {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 94px;
    img {
      width: 600px;
      height: 226px;
    }
    .centersone {
      margin-right: 50px;
    }
  }
}
.moadals {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  .modalscenter {
    width: 50%;
    height: 580px;
    margin: 0 auto;
    margin-top: 10%;
    .vadeols {
      width: 100%;
      height: 100%;
    }
    .removeguanboi {
      position: absolute;
      top: 17%;
      right: 20%;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      overflow: hidden;
      text-align: center;
      line-height: 20px;
      background: #ffffff;
      color: #000000;
      font-size: 12px;
      cursor: pointer;
    }
  }
}
</style>